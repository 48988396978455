@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
	--primary-color: #2770f6;
	--background-color: #1c1c1c;
	--background-color-mobile: #333;
	--text-color: #fff;
	--text-color-hover: #ffd700;
	--box-shadow-color: rgba(123, 124, 123, 0.2);
	--border-radius: 8px;
}

h1,
h2,
h3,
p {
	font-family: 'Montserrat', sans-serif;
}

h1 {
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 1.2;
}

h2 {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.3;
}

h3 {
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 1.4;
}

p {
	font-size: 1rem;
	line-height: 1.6;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

.body-wraper {
	background: var(--background-color);
	background: linear-gradient(
		360deg,
		var(--background-color) 10%,
		var(--primary-color) 360%
	);
	color: var(--text-color);
	font-family: Libre Baskerville, sans-serif, Arial, Helvetica, sans-serif;
	overflow-x: hidden;
	scroll-behavior: smooth;
	height: 100%;
	width: 100%;

	.high-text {
		opacity: 1 !important;
		cursor: pointer;
	}
	.medium-text {
		opacity: 0.8;
	}
	.text {
		opacity: 0.7;
	}

	.skill-btn {
		padding: 5px;
		margin: 5px;
		border-radius: var(--border-radius);
		color: var(--text-color);
		text-align: center;
		box-shadow: 1px 1px 1px var(--box-shadow-color);
	}

	.work-history {
		z-index: 2;

		img {
			border-radius: 5px;
		}
		.work-history-wraper {
			position: relative;
			padding: 30px 0;
			transition: box-shadow 0.3s;
		}

		.work-history-wraper::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: var(--border-radius);
			background: rgba(71, 71, 71, 0.2);
			opacity: 0;
			z-index: 0;
			pointer-events: none;
			transition: opacity 0.3s;
			color: var(--background-color);
		}

		.work-history-wraper:hover::before {
			opacity: 1;
		}

		.work-history-wraper:hover h3 {
			color: var(--text-color-hover);
		}
	}
}

@media screen and (max-width: 768px) {
	.body-wraper {
		background-color: var(--background-color-mobile);
	}
}
